@import "../abstracts/typography";
@import "../abstracts/colors";
@import "../abstracts/layout";


footer {

    .home {
        border-top: solid $theme-bg-color-med-dark;
    }

    nav {
        display: grid;
        height: $bottom-nav-panel-height;
        width: 100vw;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        grid-template-areas: "left right";
        background: $panel-color;

        

        a {
            padding: 2em 0 2em 0;
            @include font-small-normal;
            color: $link-color;
            text-decoration: none;
        }

        a:nth-child(1) {
            grid-area: left;
            justify-self: end;
            margin-right: 0.5em;
        }

        a:nth-child(2) {
            grid-area: right;
        }
    }
}


@media only screen and (max-width: 720px) {
    footer {
        nav {
            a {
                font-size: 1em;
            }
        }
    }
}

@media only screen and (max-width: 380px) {
    footer {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        grid-template-areas: "left right";
    }
}

