@import '../abstracts/typography';
@import '../abstracts/colors';

.titleSegment {
    background: $blue-linear;
    color: $title-foreground-color;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    img {
        width: 25vw;
    }

    h1 { 
        @include font-large-title;
        font-size: 6vw;
    }

    h2 { 
        @include font-large-title;
        font-size: 2vw;
    }  
}


@media only screen and (max-width: $layout-mobile) {
    .titleSegment h2 {
        display: none;
    }
}