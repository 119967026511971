@import "../abstracts/typography";
@import "../abstracts/layout";
@mixin header-panel {
  display: grid;
  grid-template-columns: 10% auto 20%;
  grid-template-rows: auto;
  grid-template-areas: ". title .";
  margin-left: unset;
  margin-right: unset;
  min-width: calc(100vw - 120px);
  button {
    background: transparent;
    border: none;
  }

  h1 {
    @include font-large-title;
    justify-self: center;
    align-self: center;
    grid-area: title;
  }

  nav {
    padding: 1em 1em 1em 1em;
  }

  nav.previous {
    justify-self: center;
  }
  nav.next {
    justify-self: right;
  }

  p {
    @include font-header-emphasis;
    color: black;
  }
}

main.tutorial {
  width: 100vw;
  header {
    @include header-panel;
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 5em 0;
  }
}
article {
  margin: 2em 0 2em 0;
  width: 100%;
  max-width: 50%;

  &.video {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: unset;
    margin-right: unset;
  }
}

@media only screen and (max-width: $layout-mobile) {
  main.tutorial {
    header {
      nav {
        display: none;
      }
    }

    section {
      align-items: stretch;
    }
  }

  article {
    width: 100vw;
    margin: unset;
    max-width: unset;
  }
}
