@import '../abstracts/colors';

div.card-image {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-template-areas: "left right";
    width: 300px;

    img {
        grid-area: 1 / 1 / 1 / 2;
        width: 300px;
    }



    .card-image-title {
        grid-area: right;
        width: 150px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-content: center;
        justify-content: center;

        svg {
            width: 150px;
            height: 30px;
        }

        text {
            font-size: 30px;
            stroke-linejoin: round;
            text-anchor: middle;
            stroke: white;
            stroke-width: 1px;
            paint-order: stroke;
        }

        svg:nth-child(odd) {
            text {
                fill: $card-image-title-odd;
            }
        }

        svg:nth-child(even) {
            text {
                fill: $card-image-title-even;
            }
        }
    }
}