@import "../abstracts/colors.scss";
@import "../abstracts/typography";
@import "../abstracts/layout";

@mixin panel {
    position: fixed;
    background-color: $panel-color;

    a {
        color: $link-color;
    }
}

nav.navigationPanel {
    @include panel;
    z-index: 1000;
    width: 100%;
    height: $top-nav-panel-height;

    display: flex;
    flex-direction: row;
    background: $top-nav-bar-bg-color;

    border-bottom: solid $theme-bg-color-med-dark;

    button.home {
        @include font-medium-title;
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        border: none;
        background: transparent;

        img {
            padding: 0.2em 1em 0.2em 1em;
            height: $top-nav-panel-height;
        }

        p {
            padding: 0.5em 0 0 1em;
            height: $top-nav-panel-height;
        }
    }

    div {
        display: flex;
        flex-direction: column;
        padding: 0 0 0 0;

        
        div {
            padding: 1em 1em 1em 1em;
            @include font-medium-title;
            color: $link-color;
            align-self: flex-end;

            &.over {
                background: $top-nav-bar-bg-over-color;
            }
        }

        div.dropdown {
            width: 300px;
        }

        nav {
            display: flex;
            flex-direction: column;
            border-radius: 10px;
            display: none;
            background: $top-nav-bar-bg-color;

            button {
                padding: 1em 1em 1em 1em;
                @include font-medium-title;
                color: $link-color;
                align-self: stretch;
                border: none;
                background: transparent;
                &:hover {
                    background: $top-nav-bar-bg-over-color;
                }
            }
        }

        nav.over {
            display: flex;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: $layout-mobile) {
    nav.navigationPanel button.home p {
        font-size: 0.75em;
    }

    nav.navigationPanel div nav button {
        font-size: 0.75em;
    }
}


@media only screen and (max-width: $layout-mobile-phone) {
    nav.navigationPanel button.home p {
        display: none;
    }
}
