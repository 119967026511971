@import "../abstracts/typography";

.markdown {
    pre {
        border: 1px solid rgba(34, 36, 38, 0.15);
        box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
        background-color: white;
        padding: 1.5rem 1.5rem 1.5rem 1.5rem;
        border-radius: 1rem;
    }

    p {
        @include font-large-normal;
    }
}

@keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

section.loading {
    display: flex;
    flex-direction: column;

    & > p {
        @include font-large-emphasis;
        align-self: center;
    }

    & > img {
        align-self: center;
        width: 50px;
        height: 50px;
        animation: rotating 2s linear infinite;
    }
}
