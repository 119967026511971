@import '../abstracts//layout';

div.videolist {
    display: flex;
    flex-flow: row wrap;
    width: calc(100vw - 400px);
    gap: 3em 1.5em;
    padding: 2em; 
}


@media only screen and (max-width: $layout-mobile) {
    div.videolist {
        flex-wrap: unset;
        gap: 1em 0;
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
}
