$theme-color-dark:rgb(7,36,72);
$theme-color-med-dark:rgb(84,210,210);
$theme-color-med-medium:rgb(255,203,0);
$theme-color-med-light:rgb(248,170,75);
$theme-color-light:rgb(255,97,80);

$theme-bg-color-dark:rgb(39,38,67);
$theme-bg-color-med-dark:rgb(44,105,141);
$theme-bg-color-med-medium:rgb(186,232,232);
$theme-bg-color-med-light:rgb(227,246,245);
$theme-bg-color-light:rgb(255,255,255);

$theme-blue-1:rgb(39,38,67);
$theme-blue-2:rgb(44,105,141);
$theme-blue-3:rgb(186,232,232);
$theme-blue-4:rgb(227,246,245);
$theme-blue-5:rgb(255,255,255);

$theme-yellow-1:rgb(7,36,72);
$theme-yellow-2:rgb(84,210,210);
$theme-yellow-3:rgb(255,203,0);
$theme-yellow-4:rgb(248,170,75);
$theme-yellow-5:rgb(255,97,80);

$theme-green-1 : rgb(69,77,102);
$theme-green-2 : rgb(48,153,117);
$theme-green-3 : rgb(88,179,104);
$theme-green-4 : rgb(218,216,115);
$theme-green-5 : rgb(239,238,180);

$panel-color: $theme-bg-color-light;
$link-color: black;
$link-highlight-color: rgb(128, 128, 128);
$link-background-highlight-color: rgb(240, 240, 240);

$blue-linear : linear-gradient(.25turn, $theme-blue-1, 50%,$theme-blue-2);
$yellow-linear : linear-gradient(0.25turn, $theme-yellow-4, 50%, $theme-yellow-3);
$green-linear : linear-gradient(0.25turn, $theme-green-2, 50%, $theme-green-3);
$red-linear : linear-gradient(0.25turn, $theme-yellow-4, 50%, $theme-yellow-5);

$title-foreground-color : $theme-bg-color-med-light;

$top-nav-bar-bg-color : $theme-bg-color-light;
$top-nav-bar-bg-over-color : $theme-bg-color-med-light;

$page-background-color : linear-gradient(.25turn, $theme-bg-color-med-light, 50%,$theme-bg-color-light);

$card-image-title-stroke : white;
$card-image-title-odd:rgb(186,232,232);
$card-image-title-even:rgb(255,203,0);