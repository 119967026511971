@import "./layout";
@import "./colors";
@import "./typography";

@mixin panel($panel-width: $layout-mobile-phone) {
    width: $panel-width;
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: unset;
}

aside {
    &.panel {
        @include font-large-emphasis;
        @include panel;
        background: $yellow-linear;
        min-height: calc(100vh - 50px);
        padding-top: 3em;

        p {
            padding: 1em 0.25em 0 0.25em;
        }

        &.green {
            @extend .panel;
            background : $green-linear;
        }

        &.red {
            @extend .panel;
            background : $red-linear;
        }
    }

    &.green-panel {
        @extend .panel;
        background : $green-linear;
    }
}

section {
    text-align: left;

    &.horizontal {
        display: flex;
        flex-direction: row;
    }

    & > figure {
        @include panel;
        background: $yellow-linear;
        height: $layout-mobile;
        justify-content: center;
        p {
            @include font-regular-emphasis;
            padding: 2em;
        }
    }
}

@media only screen and (max-width: $layout-mobile) {
    section {
        &.horizontal {
            flex-direction: column;

            aside {
                @include panel(100%);
                min-height: unset;
                padding-bottom: 3em;
            }
        }
    }
}
