@mixin font-small-normal { 
    font-family: 'Roboto';
    font-size: 2em;
}


@mixin font-regular-normal { 
    font-family: 'Roboto';
    font-size: 1.5em;
}

@mixin font-large-normal { 
    font-family: 'Roboto';
    font-size: 2em;
}

@mixin font-header-emphasis { 
    font-family: 'Roboto';
    font-size: 1.5em;
    font-weight: bold;
}

@mixin font-regular-emphasis { 
    @include font-regular-normal;
    font-weight: bold;
}

@mixin font-large-emphasis { 
    @include font-regular-emphasis;
    font-size: 2em;
}

@mixin font-medium-title { 
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    font-size: 2em;
}

@mixin font-large-title { 
    /*font-family: 'Mate SC', serif;*/
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    font-size: 5em;
}

@mixin titleFont {
    /*font-family: 'Mate SC', serif;*/
    font-family: 'Nunito', sans-serif;
    font-weight: normal;
    text-align: center;
    margin-bottom: unset !important;
}

