@import "../abstracts/colors";

button.card {
    padding-top: 2em;
    border: 1px solid;
    background: transparent;

    .innerFrame {
        display: flex;
        flex-direction: column;
        color: $link-color;
        

        &:hover {
            color: $link-highlight-color;
        }

        /*div {
            background: linear-gradient(.5turn, white, 80%,black);
        }*/
        
    }



    /*img {
        
        width: 300px;
        grid-area: image;
        &:hover {
            opacity: 70%;
            transition: opacity 0.2s ease-in-out;
        }
        
    }*/

    svg {
        grid-area: icon;
    }

    h3 {
        grid-area: title;
    }

    h4 {
        grid-area: caption;
        margin: unset;
    }
}
