section.videoSelection {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;

    p {
        @include font-large-emphasis;
        grid-area: title;
        padding: 1em 1em 1em 1em;
    }

    div.carousell {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        a {
            padding: 1em 1em 1em 1em;
        }

        nav {
            position: relative;

            div.before {
                position: absolute;
                top: 70px;
                left: -5px;
            }

            div.after {
                position: absolute;
                top: 70px;
                left: -35px;
            }
        }
    }
}

@media only screen and (max-width: 820px) {
    section.videoSelection {
        height: 100%;
    }
}
