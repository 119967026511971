@import '../abstracts/panel';
@import '../abstracts/layout';

main.home {
    padding: unset;
    margin: unset;
    display: flex;
    margin-top: $top-nav-panel-height;
    flex-direction: column;
    width: 100%;
}

@media only screen and (max-width: $layout-mobile) {
    main.home {
        margin-left: unset;
        margin-top: $top-nav-panel-height;
    }
}


@media only screen and (max-width: 1000px) {
    section {
        & > figure {
            width: 100%;
        }
    }
}
