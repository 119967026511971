@import "../abstracts/panel";
@import "../abstracts/colors";
@import "../abstracts/typography";
@import "../abstracts/layout";

@import "../molecules/HomeFooter.scss";
@import "../molecules/card.scss";
@import "../molecules/codebox.scss";
@import "../molecules/codeSnippet.scss";
@import "../molecules/TitleSegment.scss";
@import "../molecules/HomeVideoSelection.scss";
@import "../molecules/cardImage.scss";

@import "../molecules/Marquee.scss";
@import "../molecules/imageRoll.scss";

@import "../organisms/TutorialMarkdown.scss";
@import "../organisms/videolist";
@import "../organisms/NavigationPanel.scss";

@import "../templates/Home.scss";
@import "./tutorialPage.scss";


html {
  background : $page-background-color;
  height: auto;   
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background: transparent;
  position: absolute;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  font-size : 14px; /* the base of em */
}

nav.router {
  display: flex;
  flex-direction: row;
  height: 100%;
}

main {
  margin-top: $top-nav-panel-height;
  min-height: calc(100vh - 60px);
}

@media only screen and (max-width: $layout-mobile) {
  main {
      margin-left: unset;
      margin-top: $top-nav-panel-height;
  }
}