img {

    &.imageRollImage.fadeout {
        opacity: 0;
    }

    &.imageRollImage {
        transition: opacity 1s ease-in-out;
        position: absolute;
        width: 80%;
        height: 900px;
        z-index: -10;
    }
}

div.imageRoll {
    min-height: 900px;
}

@media only screen and (max-width: 1000px) {
    div.image-roll-automatic-hide {
        display:none;
    }
}
